import { Icon } from '../icon';
import type { IconProps } from '../icon';

export function IconFlagCustom(props: IconProps) {
	return (
		<Icon viewBox="0 0 61 60" {...props}>
			<path
				d="M10.333 39.218c14.546-11.374 25.455 11.373 40 0V10.783c-14.545 11.373-25.454-11.374-40 0v28.434Z"
				fill="#E6F2F3"
			/>
			<path
				d="M10.333 52.5V39.218m0 0c14.546-11.374 25.455 11.373 40 0V10.783c-14.545 11.373-25.454-11.374-40 0v28.434Z"
				stroke="#027F88"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</Icon>
	);
}
