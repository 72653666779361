import { Icon } from '../icon';
import type { IconProps } from '../icon';

export function IconCalendarCustom(props: IconProps) {
	return (
		<Icon viewBox="0 0 60 60" {...props}>
			<path
				fill="#E6F2F3"
				d="M18 10h24c2.8 0 4.2 0 5.3.5a5 5 0 0 1 2.2 2.2c.5 1.1.5 2.5.5 5.3v24c0 2.8 0 4.2-.5 5.3a5 5 0 0 1-2.2 2.2c-1.1.5-2.5.5-5.3.5H18c-2.8 0-4.2 0-5.3-.5a5 5 0 0 1-2.2-2.2c-.5-1.1-.5-2.5-.5-5.3V18c0-2.8 0-4.2.5-5.3a5 5 0 0 1 2.2-2.2c1.1-.5 2.5-.5 5.3-.5Z"
			/>
			<path
				stroke="#027F88"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="M10 20h40m-40 0v22c0 2.8 0 4.2.5 5.3a5 5 0 0 0 2.2 2.2c1.1.5 2.5.5 5.3.5h24c2.8 0 4.2 0 5.3-.5a5 5 0 0 0 2.2-2.2c.5-1.1.5-2.5.5-5.3V20m-40 0v-2c0-2.8 0-4.2.5-5.3a5 5 0 0 1 2.2-2.2c1.1-.5 2.5-.5 5.3-.5h2m30 10v-2c0-2.8 0-4.2-.5-5.3a5 5 0 0 0-2.2-2.2c-1.1-.5-2.5-.5-5.3-.5h-2m-20 0h20m-20 0V5m20 5V5m-2.5 25-10 10-5-5"
			/>
		</Icon>
	);
}
